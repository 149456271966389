import common from "@/common";

export default [
  {
    name: 'companyName',
    label: 'common.company',
    type: 'text',
    show: common.isAdmin(),
    edit_disable: true
  },
  {
    name: 'facilityName',
    label: 'common.facility',
    type: 'text',
    slot: 'facility',
    show: common.isAdmin(),
    edit_disable: true
  },
  {
    name: 'facilityCode',
    label: '院舍編號',
    type: 'text',
    edit_disable: true
  },
  {
    name: 'collectionID',
    label: 'CollectionID',
    rule: 'required',
    type: 'text',
  },

]
